import React, {useState, useEffect, useRef} from 'react';
import { /* useCMA, */ useSDK } from '@contentful/react-apps-toolkit';
import { Editor } from "@tinymce/tinymce-react";

const Field = () => {
  const sdk = useSDK();
  sdk.window.updateHeight(700)
  const [contentEditor, setContentEditor] = useState( sdk.field.getValue() || {content: ""} );
  const handleEditorChange = (body, editor) => {
    setContentEditor({content: body})
  }
  const editorRef = useRef(null);

  async function openDialog(editor) {
    //sdk.dialogs.selectMultipleAssets() //純正メディアライブラリ
    sdk.dialogs
    .openCurrentApp({
      width: 1400,
      minHeight: 600,
      position: 'center',
      shouldCloseOnOverlayClick: true,
      shouldCloseOnEscapePress: true
      //parameters: { ...config, maxFiles },
      })
    .then((data) => {
      if(data && "assets" in data){
        data["assets"].map(( image ) => (
          editor.insertContent(`<img src=${image.url || ""} width="300px" />`)
        ))
      }
    });
  }

  useEffect(() => {
    sdk.field.setValue(contentEditor)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentEditor]);

  useEffect(() => {
    sdk.window.stopAutoResizer()
  });

  return (
    <>
      <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        apiKey="y6r7ts2jqgv22y7pyxc1fx10kytvhsf1r7ri1lcivg6aagkv"
        onEditorChange={handleEditorChange}
        init={{
          selector: 'textarea',
          height: 700,
          file_picker_types: 'image',
          //images_upload_handler: handleUpload,
          //plugins: 'image anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen powerpaste advtable advcode editimage tableofcontents mergetags inlinecss',
          plugins: 'image anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount code',
          toolbar: 'cloudinary code | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags align lineheight | checklist numlist bullist indent outdent | emoticons charmap removeformat undo redo',
          setup: function (editor) {
            editor.ui.registry.addButton('cloudinary', {
              text: 'Open Cloudinary',
              onAction: function() {
                openDialog(editor)
              }
            })
          }
        }}
        value={ contentEditor["content"] || "" }
      />
    </>
  );
};

export default Field;
