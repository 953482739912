import React from 'react';
import { Spinner, Flex } from '@contentful/f36-components';
import { /* useCMA, */ useSDK } from '@contentful/react-apps-toolkit';
import { init } from '@contentful/app-sdk';
import { loadScript } from '../utils';

const Dialog = () => {
  const sdk = useSDK();

  async function openCloudinary() {
    await loadScript('https://media-library.cloudinary.com/global/all.js');
    const { cloudinary } = window;
    const cloudName = "dww72thck"; // replace with your own cloud name
    const api_key = "831731549412574"; // replace with your own upload preset
    const instance = cloudinary.createMediaLibrary(
      {
        cloud_name: cloudName,
        api_key: api_key,
        remove_header: true,
      },
      {
        insertHandler: (data) => sdk.close(data),
      }
    )
    instance.show();
  }

  init((sdk) => {
    openCloudinary()
  });
  
  return (
    <Flex flexDirection="column" gap="spacingS" alignItems="center" justifyContent="center" style={{height: "500px"}}>
      <Spinner size="large" />
    </Flex>
  )
};

export default Dialog;